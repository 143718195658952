import React from "react"
import { graphql, PageProps } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/Layout"

const MdxPage = ({
  data: { mdx },
}: PageProps<GatsbyTypes.MDXPageQuery>) => {

  const { frontmatter, body = "" } = mdx ?? {};

  return (
    <Layout title={frontmatter?.title}>
      <MDXRenderer
        remoteImages={frontmatter?.embeddedImagesRemote}
        localImages={frontmatter?.localImages}
      >
        {body}
      </MDXRenderer>
    </Layout>
  )
}

export const query = graphql`
  query MDXPage ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        embeddedImagesRemote {
          childImageSharp {
            gatsbyImageData(quality: 50, layout: CONSTRAINED, width: 500, formats: [AUTO, WEBP, AVIF])
          }
        }
        localImages {
          childImageSharp {
            gatsbyImageData(quality: 50, layout: CONSTRAINED, width: 500, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      body
    }
  }
`

export default MdxPage
